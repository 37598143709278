import { GeneralErrorBoundary } from "./error-boundary.tsx";

export const ErrorBoundary = GeneralErrorBoundary;
//  () => {
//   const isDev = useIsDevmode();
//   const user = useOptionalUser();
//   const error = useRouteError() as ProviderActionResponse;

//   const ErrorMessage = () => {
//     if (isRouteErrorResponse(error)) {
//       return (
//         <Label size="M" className="text-rouge">
//           {error.data.message}
//         </Label>
//       );
//     }
//     return (
//       <Label size="M" className="text-rouge">
//         {error?.message}
//       </Label>
//     );
//   };
//   const { isLargeScreen } = useTailwindScreenSizes({});
//   const { phoneDisplay, phoneLink, CONTACT_EMAIL } = useEnv() || {};

//   return (
//     <>
//       <HomeSection
//         color={isLargeScreen ? "gray100" : "white"}
//         verticalPadding={"clint"}
//         horizontalPadding={"clint"}
//       >
//         <HomeArticle color="transparent" horizontalPadding="none">
//           <div className="relative flex flex-wrap items-center gap-8 lg:gap-12">
//             <div className="inset-0 z-20 flex items-center relative lg:absolute">
//               <div
//                 className={cn(
//                   "rounded-clint flex flex-col gap-5 lg:border lg:border-gray-200 bg-white lg:p-[2.125rem] lg:basis-[672px]",
//                   {},
//                 )}
//               >
//                 <div className="flex flex-col gap-2">
//                   <Label size="M" className="text-gray-600">
//                     Erreur 404
//                   </Label>
//                   <Headline size="h1" as="div">
//                     Oups BadCollect ...
//                   </Headline>
//                 </div>
//                 <Label size="M" className="py-5 text-xl">
//                   Nous n'arrivons pas à trouver la page que vous recherchez.
//                   Essayez de revenir à la page d'accueil.
//                 </Label>

//                 {isDev || user?.authOptions.isAdmin ? <ErrorMessage /> : null}

//                 <ClintButton
//                   as="Link"
//                   linkProps={{
//                     to: "/",
//                     reloadDocument: true,
//                   }}
//                   variant="primary"
//                   size="lg"
//                   labelSize="L"
//                   className="w-fit"
//                 >
//                   Revenir à l'accueil
//                 </ClintButton>
//                 <div className="flex w-full flex-wrap items-center gap-8">
//                   <ClintButton
//                     as="Link"
//                     iconPosition="left"
//                     linkProps={{
//                       to: phoneLink || "#",
//                       reloadDocument: true,
//                     }}
//                     variant="secondaryGray100"
//                     size="lg"
//                     Icon={Phone}
//                     className="grow basis-[240px]"
//                   >
//                     {phoneDisplay}
//                   </ClintButton>
//                   <ClintButton
//                     as="Link"
//                     iconPosition="left"
//                     linkProps={{
//                       to: `mailto:${CONTACT_EMAIL}` || "#",
//                       reloadDocument: true,
//                     }}
//                     variant="secondaryGray100"
//                     size="lg"
//                     Icon={Mail}
//                     className="grow basis-[240px]"
//                   >
//                     {CONTACT_EMAIL}
//                   </ClintButton>
//                 </div>
//               </div>
//             </div>

//             <div className="relative ml-auto w-fit lg:min-w-[500px] lg:pl-64">
//               <OptimizedImage
//                 maxWidth={1440 * 1.5}
//                 width={1440 * 1.5}
//                 height={694 * 1.5}
//                 source={errorImage}
//                 className="h-auto w-full ml-auto "
//                 containerClassName="mx-0 relative rounded-clint overflow-hidden"
//                 imageProps={{
//                   minHeight: "100%",
//                   objectFit: "cover",
//                 }}
//               >
//                 <div className="absolute inset-0 z-10 flex h-full w-full items-center justify-center">
//                   <img src={vector} className="" alt="" />
//                 </div>
//               </OptimizedImage>
//             </div>
//           </div>
//         </HomeArticle>
//       </HomeSection>
//       <ContactUsSection />
//     </>
//   );
// };
